.terms-container {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.terms-header {
  border-bottom: 1px solid #ddd;
  background: #050F26;
  color: white;
  padding: 60px 80px;
 overflow: hidden;
}

.terms-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoSvg-user-manual {
  position: absolute !important;
  top: -125px;
  height: 250px !important;
  width: 250px !important;
}
.terms-title h1 {
  font-size: 2.5rem;
}

.terms-title h2 {
  font-size: 1.5rem;
}

.terms-section {
  margin-bottom: 20px;
}

.terms-section h3 {
  font-size: 1.2rem;
  color: #4b2e83;
  margin-bottom: 10px;
}

.terms-section p,
.terms-section ul {
  font-size: 1rem;
  margin-left: 20px;
}

.terms-section ul {
  list-style-type: disc;
}
