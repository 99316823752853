.maintenance-background{
  background-color: #2034e4;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.maintenance-container{
  height: 100vh;
  width: 100vw;
}

maintenance h1 {
  /* font-weight: 100; */
  font-size: 27pt;
  /* color: #e43; */
}

maintenance p {
  /* font-weight: 300; */
}

maintenance .warning-content {
  position: absolute;
  top: 25%;
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 0;
}

maintenance .navbar-brand {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  margin: auto;
}

maintenance .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
