/*login*/

.login h2 {
  color: #000;
}
.getstarted1 a {
  color: #fff;
  text-decoration: none;
}
/* .bg {
  background-color: #fff;
  padding-bottom: 30px;
} */
.login_h1 {
  font-size: 30px;
}

.login_text {
  color: #fff;
  margin-bottom: 54px;
  margin-left: 63px;
}
.bg-intro-custom {
  background-color: #050f26;
}
.bg-left {
  background-repeat: no-repeat;
  height: 100vh;
}
.login_text strong {
  background-color: #bcf0da;
  font-weight: 400;
  border-radius: 5px;
  color: green;
  padding: 2px 18px;
  font-size: 12px;
  margin-right: 10px;
}
.getstarted_face {
  margin-top: 75px !important;
  max-width: 716px;
  margin: 0 auto;
}
.get_started_text {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 20px;
}
.rounded {
  margin-top: 100px;
}
.custom-font-weight-director {
  font-weight: 700;
}

.bg-left-second {
  margin-top: 56px;
}

.login_h1 {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}

.step_first_top h3 {
  font-size: 40px;
  color: #000;
  font-weight: 700;
  margin-bottom: 15px;
}

/*signup flow*/

.signup_margin {
  margin: 40px;
}
.sign_mar {
  margin-top: 0;
}
.signup_form {
  margin-left: 65px;
  margin-top: 55px;
}
.usa_step1 {
  border: 1px solid #dee2e6;
  height: 37px;
  padding: 7px 20px;
  min-width: 80px;
  background-color: #f9fafb;
  font-weight: 700;
}
.step_first_top p {
  background: #e1effd;
  padding: 20px;
  color: #4778e3;
  border-radius: 5px;
  margin-bottom: 40px;
}
.login_text p,
h1 {
  color: #fff;
}
.white-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.step-badge {
  width: 40px;
  height: 40px;
  background-color: rgb(185, 94, 185);
  border-radius: 50%;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.step-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.step-text {
  margin: 0;
}
.step-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.step {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.step-badge {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #a4cafe;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: 500;
}

.step-text {
  margin: 0;
}

.step-group {
  display: flex;
  flex-direction: column;
}

/* Add this CSS to your existing CSS file or component */
.flow-arrow {
  position: relative;
  width: 0;
  height: 0;
  border-left: 10px solid transparent; 
  border-right: 10px solid transparent; 
}


.step-group:nth-child(1) .flow-arrow::after {
  content: "";
  position: absolute;
  top: 34%;
  right: -7px;
  transform: translate(-50%, -50%);
  border: 1px solid #d1d5db;
  height: 40px;
}


.step-group:nth-child(2) .flow-arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: 20px solid #fff; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.intro_p {
  background: #e1effd;
  color: #4778e3;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
}
/*about business page*/
#pet-select {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  border-color: #d1d5db;
}
.banner-login img {
  min-height: 900px;
  max-width: 100%;
  right: -40px;
}
.step_four_top .step4 {
  background: none;
  color: #000;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
}
form {
  font-size: 17px;
  font-weight: 500;
}
.getstarted1 {
  margin-bottom: 20px;
}
.countryselect {
  width: 100%;
}
.youhaveshare {
  text-align: center;
}
.youhaveshare strong {
  color: #3164f2;
  font-weight: 700;
}
.incorp {
  color: #5883f8;
}
.payment {
  border: 2px solid #e5e7eb;
  box-shadow: #dee2e6;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
}
.submit_dashboard a {
  color: #000;
}
.one-time-free {
  background: #1c64f2;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 37px;
}
.selectplan {
  margin-top: 75px !important;
  margin-left: 47px;
}
.rm1500 {
  float: right;
  margin-top: -66px;
}
.makepayment_top h5 {
  text-align: center;
}
.makepayment_top p {
  text-align: center;
}
/*forgot_password*/
.forgot_password img {
  max-width: 100%;
  min-height: 700px;
  height: 100vh;
}
.forgot_button {
  background-color: #0061d5;
  border-radius: 10px;
  color: #fff;
  height: 40px;
  margin: 10px 0;
  font-size: 14px;
}
.forgot_text {
  font-weight: 700;
}
.forget_margin {
  margin-left: 106px;
  margin-top: 100px;
}
/*tab styling*/
.tab_border {
  border: 2px solid #e5e7eb;
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 40px;
}
.tab_con {
  background-color: #f3f4f6;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}
.tab_con .additional-text {
  margin-top: 10px;
}
.bg-left-select img {
  max-width: 100%;
  min-height: 900px;
}
.addanotherdirector_btn {
  min-width: 650px;
}
/*dashboards styling*/
.welcome_dashbaord {
  background-image: url(https://digitxgroup.com/wp-content/uploads/2024/04/bg_card_newdash-65e85ec696cc90be0516a1342ab33a2ba70ace2b2f2c0c3748d32c682f7d19a2.png);
  background-color: #103da0;
  padding: 19px;
  border-radius: 5px;
  padding-right: 189px;
}
.dashboardleft_h {
  font-size: 15px;
  color: #fff;
  font-weight: 700;
}
.welcome_dashbaord p {
  color: #fff;
  font-size: 13px;
}
.welcome_dashbaord a {
  color: #fff;
}
/* .dashboards_first_left h1 {
  font-size: 20px;
  color: #000;
} */
.dashboards_first_left p {
  font-size: 12px;
}
.dashboards_first_left {
  margin-top: 30px;
  margin-bottom: 26px;
}
.column-right_dash_first {
  margin-top: 30px;
}
.btn-1_dash a {
  text-decoration: none;
  /* width: 115px;
  height: 26px; */
  background-color: #f3f4f6;
  color: #3b414d;
  text-align: center;
  font-size: 10px;
  padding: 5px 10px;
  margin-right: 5px;
  font-weight: 700;
  border-radius: 5px;
  float: right;
}
.btn-2_dash a {
  /* width: 158px; */
  background-color: #0f68ea;
  color: #fff;
}
.dashboards_bg {
  background-color: #e0f2ff;
}
.document_billing a {
  background-color: #fff;
  border: 1px solid #f1f2f4;
  color: #000;
  width: 100%;
  /* float: left; */
  height: 46px;
  margin: 0;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* margin-left: 5px; */
}
.document_billing a:hover {
  background: #0f68ea;
  color: #fff;
}
/*invoices styling*/
.invoices_bg {
  background-color: #f3f6fb;
  border-radius: 8px;
  margin-top: 20px;
}
.invoices_img {
  max-width: 400px;
}
.invoices_right {
  margin-top: 106px;
  margin-left: 50px;
  margin-right: 30px;
}
.invoices_right strong {
  font-weight: 500;
  background-color: #e5e7eb;
  border-radius: 6px;
  font-size: 9px;
  padding: 4px 11px;
}
.invoices_right h5 {
  margin-top: 10px;
}
.invoices_right p {
  font-size: 14px;
}
.bottom_text_invoces {
  text-align: center;
  font-size: 12px;
}
.account_bg {
  background-color: #103da0;
  border-radius: 5px;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 40px;
}
/*account styling*/
.account {
  margin: 25px 36px;
}
.account h5 {
  color: #fff;
}
.account p {
  color: #fff;
  font-size: 13px;
}
.btn_account {
  width: 147px;
  height: 40px;
  background-color: #fff;
  color: #000;
  text-align: center;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  margin-left: 32px;
  margin-bottom: 23px;
}

/*sidebar menu*/
.containerSide {
  height: screen;
  overflow: hidden;
  background-color: #050f26;
  transition: all 0.5s;
}
main {
  width: 100%;
  width: -webkit-fill-available;
  padding: 0px 20px 0px 20px;
  background-color: #e0f2ff;
}

.sidebar {
  background: #050f26;
  color: #fff;
  height: 100vh;
  position: fixed;
  transition: all 0.5s;
  overflow: hidden;
  max-height: 100vh;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width */
}

/* Hide scrollbar thumb (drag handle) */
.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.custom-transition {
  transition: all 0.5s;
}
.top_section {
  display: flex;
  align-items: center;
  /* padding: 20px 15px; */
  /* margin-bottom: 10px; */
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: #fff;
  padding: 15px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  margin-top: 7px;
}
.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active {
  background: lightskyblue;
  color: #000;
}
.icon,
.link_text {
  font-size: 15px;
}
.sidebar_logo {
  max-width: 100px;
  max-height: 77px;
}

.account_menu {
  position: fixed;
  bottom: 20px;
  left: 153px;
  color: #000000;
  border-radius: 0px 5px 5px 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.5s;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
}
.account_menu_item {
  padding: 5px 13px;
  cursor: pointer;
  max-width: 227px;
  display: block;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  background: #050f26;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 15px;
}

.account_menu_item:hover {
  background-color: blue;
  color: #fff;
}
/*legal page styling*/
.legal_right strong {
  background-color: #0e9f6e;
  color: #fff;
}
/*banks page styling*/
.bank_bg {
  margin-top: 50px;
}
.bank_sec {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* padding: 10px; */
  /* margin-bottom: 15px; */
  border-radius: 10px; 
}
.bank_logo {
  float: left;
  margin-right: 20px;
}
.bank_img {
  max-width: 83px;
  max-height: 83px;
}
.bank_text {
  position: relative;
}
.bank_text h5 {
  font-size: 17px;
}
.bank_text p {
  font-size: 12px;
  margin-bottom: 0px;
}
.banks_right {
  margin-top: 82px;
}
/*perks styling*/
.perks_p {
  font-size: 13px;
}
.perks_logo {
  background-color: #76edcf;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
}
.perks_img {
  max-width: 60px;
  max-height: 60px;
}
.perks_sec {
  padding: 0;
  border-radius: 5px;
  border: none;
}
.perks_text h5 {
  font-size: 14px;
  margin: 15px 0;
}
.perks_text span {
  width: 152px;
  height: 16px;
  border-radius: 8px;
  background-color: #e5e7eb;
  color: #000;
  font-size: 10px;
  display: inline-block;
  text-align: center;
}
.perks_text {
  padding: 10px 15px;
}
.hori_color {
  background-color: #3c763d;
}
.polisrti_color {
  background-color: #0951aa;
}
.get_color {
  background-color: #b9ebff;
}
.alignments_color {
  background-color: #edf4f5;
}
.moneymatch_color {
  background-color: #faf9f6;
}

/*your companies styling*/
.companies {
  background-color: #f3f6fb;
  height: 100vh;
  padding-top: 50px;
}
.companybutton {
  width: 177px;
  height: 38px;
  text-align: center;
  text-decoration: none;
  background-color: #0061d5;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  padding: 9px;
  border-radius: 7px;
}
.company_button_sec {
  text-align: right;
}
.role-detector {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 150px;
  height: 50px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
}
.role-detector2 {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 150px;
  height: 50px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
}
.activeBank-detector {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 130px;
  height: 40px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
}
.pendingBank {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 130px;
  height: 40px;
  background-image: linear-gradient(
    to top,
    #729100 0%,
    #eefb32 80%,
    #d3d03a 100%
  );
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #282828;
  /* text-shadow: 0 1px #fff; */
  box-shadow: 0 4px 3px 1px #f2dfff, 0 6px 8px #d6d7d9, 0 -4px 4px #b3b3b3,
    0 -6px 4px #fefefe, inset 0 0 3px 3px #25e718;
}
.processingBank {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 130px;
  height: 40px;
  background-image: linear-gradient(
    to top,
    #1e3ba5 0%,
    #4d32fb 80%,
    #513ad3 100%
  );
  border-radius: 30px;
  border: 1px solid #0a40ab;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: white;
  box-shadow: 0 4px 3px 1px #6e1b9d, 0 6px 8px #144cbc, 0 -4px 4px #b3b3b3,
    0 -6px 4px #2808ab, inset 0 0 3px 3px #3718e7;
}
.activeBank {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 130px;
  height: 40px;
  background-image: linear-gradient(
    to top,
    #009107 0%,
    #32fb4e 80%,
    #60d33a 100%
  );
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #282828;
  /* text-shadow: 0 1px #fff; */
  box-shadow: 0 4px 3px 1px #f2dfff, 0 6px 8px #d6d7d9, 0 -4px 4px #b3b3b3,
    0 -6px 4px #fefefe, inset 0 0 3px 3px #25e718;
}
/*registercompanies*/
.register_companies {
  background-color: #f3f6fb;
  height: 100vh;
  padding-top: 16px;
}
.secregister_bg {
  background-color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 70px;
}
.coming_reg {
  background-color: #f7f7f7;
  color: #000;
}
.go_back_acc {
  text-align: center;
  color: #000;
  font-size: 12px;
  text-decoration: none;
  padding-top: 24px;
}
.profile-container {
  margin: 30px;
}
/*profile styling*/
.profile-pic-card {
  font-weight: 500;
}
.photo-content .cover-photo {
  background: url("https://dompet.dexignlab.com/xhtml/images/profile/cover.jpg");
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
}
.profile .profile-photo {
  max-width: 9rem;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem;
  margin-left: 3.625rem;
}
.bank-border {
  border-bottom: 2px solid #050f26;
}
.bank-border {
  border-bottom: 2px solid #050f26;
}
.profile {
  background-color: #fff;
  border-radius: 7px;
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  padding-bottom: 28px;
  height: 100vh;
}
.profilebtn {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #050f26;
  border-radius: 16px;
  color: #000;
  background-color: #fff;
  text-decoration: none;
  font-size: 15px;
  padding: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}
.choose_image input {
  border: none !important;
}
.choose_image input .file-upload-button {
  background: #050f26;
}
.profilebtn:active,
.profilebtn:focus {
  background-color: #0951aa;
  color: #fff;
}
.profilebtn.active {
  background-color: #0951aa; /* Active background color */
  color: #fff; /* Active text color */
}
.bgprofile_left {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.btn_up {
  margin-top: 10px;
}
.edit_profile {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.intro-flow-bg {
  background-color: #012a60;
}

.lottie_login {
  height: 150px;
  width: 150px;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
}
.login {
  text-align: center;
}
.log_tex p {
  text-align: center;
}

label strong {
  color: #050f26 !important;
}
.eye_btn {
  background-color: #050f26 !important;
  border: 2px solid #050f26 !important;
  position: absolute !important;
  right: 0 !important;
  color: #fff !important;
  z-index: 50 !important;
}
input,
textarea,
select {
  border: 2px solid #494c51 !important;
}

.phone_box input {
  border: 0px !important;
}

.phone-box-selector {
  border: 2px solid #494c51 !important;
  padding: -5px;
}

.intro-margin-custom {
  margin: 40px;
}

.custom-intro-hight {
  margin-top: 150px;
}
.modal-index {
  z-index: 9999;
}
.shareholder-index {
  z-index: 10;
}
.margin-slide {
  margin-top: 100px;
}

.verticalNumber {
  right: -3rem;
  bottom: 40px;
  height: 550px;
}
.customLoading {
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .bg-white {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .slide_login {
    padding: 0px;
  }
  .slide_img_login {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .login_text {
    color: #fff;
    margin: 50px;
  }
  .bg-left {
    background-repeat: no-repeat;
    height: 85vh;
  }
  .logoSvg {
    position: absolute !important;
    top: -70px;
    height: 250px !important;
    width: 250px !important;
  }
}

/* pricing card  */

priceCard h1 {
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 2em;
  margin-bottom: 0;
}

priceCard .text-accent {
  color: #00a1ab;
}

priceCard .fs-500 {
  font-size: 1.1rem;
  margin: 0;
}

priceCard .mb {
  margin-bottom: 0.5em;
}

priceCard .plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1em 0;
}

priceCard .plan {
  border: 1px solid black;
  width: 16.5rem;
  padding: 2em;
  border-radius: 1em;
  margin: 0 0.5em 1em;
}

priceCard .plan--light {
  color: #4e4e4e;
  background: linear-gradient(-45deg, #e5e3e8, #fafafa);
}

priceCard .plan--light .plan-price {
  color: #00a1ab;
}

priceCard .plan--light .btn {
  color: #fff;
  background: #4e4e4e;
}

priceCard .plan--accent {
  color: #fff;
  background: linear-gradient(-45deg, #00a1ab, #3741a0);
}

priceCard .plan--accent .btn {
  color: #4e4e4e;
  background: #fff;
}

priceCard .plan-title {
  text-transform: uppercase;
  margin: 0 0 1em;
}

priceCard .plan-price {
  margin: 0;
  font-size: 2rem;
  line-height: 1;
  font-weight: 900;
}

priceCard .plan-price span {
  display: block;
  font-size: 1.5625rem;
  font-weight: 300;
}

priceCard .plan-description {
  margin: 2em 0;
  line-height: 1.5;
}

priceCard .btn {
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  color: #fff;
  background: #00a1ab;
  transition: 0.3s;
}

priceCard .btn:hover {
  opacity: 0.7;
}

priceCard .btn-mb {
  margin-bottom: 3em;
}

priceCard .btn--light {
  color: #4e4e4e;
  background: #fff;
}

priceCard .btn--dark {
  color: #fff;
  background: #4e4e4e;
}

priceCard .card-basic,
.card-premium,
.card-standard {
  margin: 0 2rem 1rem 0;
  padding: 0 0 0.5rem 0;
  background: #fff;
  color: #444;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
  overflow: hidden;
  transition: all 0.1ms ease-in-out;
}
.card-basic:hover,
.card-premium:hover,
.card-standard:hover {
  transform: scale(1.02);
}

priceCard .card-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 1rem 0;
  color: #fff;
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}

priceCard .header-basic,
.btn-basic {
  background: linear-gradient(135deg, rgb(0, 119, 238), #06c766);
}

priceCard .header-standard,
.btn-standard {
  background: linear-gradient(135deg, #b202c9, #cf087c);
}

priceCard .header-premium,
.btn-premium {
  background: linear-gradient(135deg, #eea300, #ee5700);
}

priceCard .card-body {
  padding: 0.5rem 0;
}
priceCard .card-body h2 {
  font-size: 2rem;
  font-weight: 700;
}

priceCard .card-element-container {
  color: #444;
  list-style: none;
}

priceCard .btn {
  margin: 0.5rem 0;
  padding: 0.7rem 1rem;
  outline: none;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.1ms ease-in-out;
}

priceCard .btn:hover {
  transform: scale(0.95);
}

priceCard .btn:active {
  transform: scale(1);
}

priceCard .card-element-hidden {
  display: none;
}

priceCard .card {
  position: relative;
  border: 2px solid #008bf8;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);

  overflow: visible;
}

priceCard .card-button {
  transform: translate(-50%, -50%);
  width: 60%;
  border-radius: 8px;
  border: 2px solid #008bf8;
  color: black;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  left: 50%;
  top: 0;
  transition: 0.3s ease-out;
}

.btn-donate {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: rgb(37, 151, 130);
  --btn-bg-2: rgb(0, 68, 176);
  --btn-bg-color: hsla(360 100% 100% / 1);

  background-size: 280% auto;
  background-image: linear-gradient(
    325deg,
    var(--btn-bg-2) 0%,
    var(--btn-bg-1) 55%,
    var(--btn-bg-2) 90%
  );
  border: none;
  box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5),
    0px 5px 5px -1px rgba(58, 125, 233, 0.25),
    inset 4px 4px 8px rgba(175, 230, 255, 0.5),
    inset -4px -4px 8px rgba(19, 95, 216, 0.35);
}

.btn-donate:is(:focus, :focus-visible, :active) {
  outline: none;
  box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
}

@media (prefers-reduced-motion: reduce) {
  .btn-donate {
    transition: linear;
  }
}

/*update and profile page styling*/
.lottie_update {
  text-align: center;
  padding: 20px;
}
.choose_image {
  display: flex;
  align-items: center;
}

.file-label {
  display: flex;
  align-items: center;
  background-color: #2c3e50;
  color: white;
  padding: 10px 15px;
  border-radius: 0px 25px 25px 0px;
  cursor: pointer;
}

.file-label:hover {
  background-color: #34495e;
}

.placeholder-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.file-label-text {
  font-size: 1rem;
}

.file-input {
  display: none;
}

.file-name {
  margin-left: 10px;
  color: #777;
}
/*review submisson page styling*/
.border_review {
  border: 2px solid #012a60;
  border-radius: 10px;
}
.border_review2 {
  border-radius: 10px;
}
.sub-heading-review {
  font-size: 16px;
  font-weight: 700;
  color: #4e2fe8;
  font-family: math;

  margin-top: 12px;
}
.text_heding_review {
  font-size: 18px;
  color: #050f26 !important;
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: 12px;
}
.border_review2 span {
  font-size: 15px;
  color: #012a60 !important;
  display: block;
  font-weight: 600;
}
.border_review span {
  font-size: 15px;
  color: #012a60 !important;
  display: block;
  font-weight: 600;
}
.genaarte_value {
  font-size: 15px;
  font-weight: 500;
  color: #16626f;
  overflow-wrap: anywhere;
}
.sparate_border {
  border-bottom: 2px solid #012a60;
  margin-bottom: 25px;
}
.user_dash_name {
  color: #050f26;
  font-size: 20px;
}
.user_data_text {
  color: #fff;
  font-size: 20px;
}

/* index table 3d card */
.cards {
  margin-top: 5px;
  padding: 20px;
  border-radius: 7px;
  backdrop-filter: blur(4px);
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.data_p_index {
  color: #000;
  margin: 5px 0;
  font-weight: 600;
}

.table_index {
  border: 2px solid #050f26;
}
.table_index tr {
  border: 2px solid #050f26;
}
.company_nav {
  background-color: #050f26;
  color: #fff;
  padding: 16px 30px;
  border-radius: 5px;
}
.company_nav h4 {
  font-size: 24px;
  margin: 0;
}
.link_company {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.link_text_company {
  padding-left: 30px;
}
.link_company:hover {
  color: #87cefa;
}
.link_text_company a:active {
  background-color: #87cefa;
}
.lottie_index {
  margin-top: 30px;
}
.lottie_index-dashboards {
  margin-top: 20px;
  background: #16315e;
  max-width: 100%;
}
.lottie_index {
  background: #16315e;
  max-width: 100%;
}
.lottie_index iframe {
  width: 100%;
  height: 100%;
}
.lottie_index-dashboards iframe {
  width: 100%;
  height: 100%;
}
customNavbar {
  display: none;
}
.account_menu2 {
  position: fixed;
  top: 218px;
  left: 170px;
  color: #000000;
  border-radius: 0px 5px 5px 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: all 0.5s;
  border-radius: 0px 15px 15px 0px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6);
}
.account_menu3 {
  position: fixed;
  top: 218px;
  left: 170px;
  color: #000000;
  border-radius: 0px 5px 5px 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: all 0.5s;
  border-radius: 0px 15px 15px 0px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6);
}

.delete-director {
  position: absolute;
  right: 10px;
  font-size: 17px;
  cursor: pointer;
  z-index: 999;
  color: #f00;
}

@media screen and (max-width: 768px) {
  .main-sidebar {
    display: none;
  }

  .main-outlet {
    margin-left: 0 !important;
  }

  customNavbar {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .containerSide {
    padding-top: 60px; 
  }
  .sidebar-mobile {
    background: #050f26;
    border-radius: 0px 0px 15px 0px;
    color: #fff;
    padding: 20px 0px;
    position: fixed;
    transition: all 0.5s;
    overflow: hidden;
    max-height: 100vh;
    overflow-y: auto;
  }
  .bars {
    color: #fff;
    font-size: 30px;
    margin-left: 20px;
  }
}

/* responsive intro flow */

@media screen and (max-width: 767px) {
  .intro-margin-custom {
    margin-top: 40px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .addanotherdirector_btn {
    min-width: 0;
  }
  .step_first_top h3 {
    font-size: 25px;
    color: #000;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .step_first_top p {
    background: #e1effd;
    padding: 20px;
    color: #4778e3;
    border-radius: 5px;

    font-size: 16px;
  }
  .custom-intro-hight {
    margin-top: 0px;
  }
  .intro-flow-img-custom-size {
    width: 320px;
  }
  .genaarte_value {
    font-size: 15px;
    font-weight: 500;
    color: #4246ff;
  }
  .get_started_text {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .rm1500 {
    float: none;
    margin-top: 0px;
  }
  newPriceCard .row {
    --bs-gutter-x: 0rem;

    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .tab_border {
    border: 2px solid #e5e7eb;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 40px;
  }
}

.company_table_head_data {
  width: 30%;
}
.company_table_body_data {
  width: 70%;
}
.company_table_head_data2 {
  width: 40%;
}
.company_table_body_data2 {
  width: 60%;
}

@media screen and (max-width: 600px) {
  .company_table_head_data {
    width: auto;
  }
  .company_table_body_data {
    width: auto;
  }
  .company_table_head_data2 {
    width: auto;
  }
  .company_table_body_data2 {
    width: auto;
  }
}

.active_company_menu {
  padding: 8px 22px;
  border-radius: 5px;
  color: black !important;
  background: lightskyblue;
  display: flex;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  margin-top: 7px;
}

.non-active_company_menu {
  padding: 8px 22px;
  border-radius: 5px;
  color: black !important;
  background: #e5e5e5;
  display: flex;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  margin-top: 7px;
}

.non-active_company_menu:hover {
  background: lightskyblue;
}
.active_company_menu2 {
  padding: 8px 22px;
  border-radius: 5px;
  color: black !important;
  border: 0.5px solid rgb(106, 198, 255);
  background: rgb(106, 198, 255);
  display: flex;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  
}

.non-active_company_menu2 {
  padding: 8px 22px;
  border-radius: 5px;
  color: black !important;
  border: 0.5px solid rgb(106, 198, 255);
  background: #d8d7d7;
  display: flex;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.non-active_company_menu2:hover {
  background: lightskyblue;
}
