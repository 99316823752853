.custom-dialog {
  position: relative;
  z-index: 10;
}

.custom-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.custom-content {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  text-align: center;
}

.custom-inner {
  transition: all 0.3s ease-out;
}
.custom-panel {
  position: relative;
  max-height: 90vh;
  width: 100%;
  max-width: 35vw; /* Adjust as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 16px;
  background-color: #fff;
  padding: 34px 34px 34px 34px;
  text-align: left;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

  /* Hide the scrollbar for webkit (Chrome, Safari, etc.) */
  scrollbar-width: none; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
}

.custom-panel::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width */
}

/* Hide scrollbar thumb (drag handle) */
.custom-panel::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.custom-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 16px;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.close-button svg {
  fill: red;
  width: 1.5rem;
  height: 1.5rem;
}

/*404 page styling*/

.page_404 {
  padding: 150px 0;
  background: #fff;
  font-family: "Arvo", serif;
  padding-bottom: 50px;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  color: #050f26;
}

.four_zero_four_bg h3 {
  font-size: 80px;
  color: #050f26;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #050f26;
  margin: 20px 0;
  display: inline-block;
  text-decoration: none;
}
.contant_box_404 {
  margin-top: -50px;
}

@media screen and (max-width: 767px) {
  .custom-panel {
    position: relative;
    max-height: 90vh;
    width: 100%;
    max-width: 90vw;
    overflow-y: auto;
    border-radius: 16px;
    background-color: #fff;
    padding: 34px 34px 34px 34px;
    text-align: left;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }
}
