searchContainer .group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 190px;
}

searchContainer .input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
}

searchContainer .input::placeholder {
  color: #9e9ea7;
}

searchContainer .input:focus,
input:hover {
  outline: none;
  border-color: rgba(0, 48, 73, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(0 48 73 / 10%);
}

searchContainer .icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

/* active tab */

userManagementComponent .card_c_s {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

userManagementComponent .card {
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  /* margin: 10px; */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

userManagementComponent .tabs {
  display: flex;
  margin-bottom: 10px;
  /* margin-left: 3px; */
}

userManagementComponent .tab {
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 5px;
  border: 1px solid #4c36d6;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}
userManagementComponent .pagination {
  display: flex;
  justify-content: center;
  margin: 17px 0;
}
userManagementComponent .tab.active {
  font-weight: bold;
  background-color: #4c36d6;
  color: white;
}

.edit-icon-admin-edit-user-profile {
  font-size: 25px;
  color: #4c36d6;
}

.tk-sign {
  font-weight: 700;
  font-size: 25px;
}

/* index css */

adminIndexTag .card {
  border-radius: 10px;
  height: 100%;
  position: relative;
  width: 100%;
}

adminIndexTag .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  opacity: 0.1;
}

adminIndexTag .bg-gradient-danger {
  background: linear-gradient(90deg, #ff6a00, #ee0979);
}

adminIndexTag .bg-gradient-info {
  background: linear-gradient(90deg, #2193b0, #6dd5ed);
}

adminIndexTag .bg-gradient-success {
  background: linear-gradient(90deg, #56ab2f, #a8e063);
}

adminIndexTag .bg-gradient-pending {
  background: linear-gradient(90deg, #f7971e, #ffd200);
}

adminIndexTag .tk-sign {
  font-family: "Arial", sans-serif;
}

adminIndexTag .card-body {
  position: relative;
}

adminIndexTag .chart {
  position: relative;
}
.userManageIcon {
  font-size: 70px;
  cursor: pointer;
  color: #1f136d;
}
.companyIcon {
  font-size: 50px;
  cursor: pointer;
  color: #1f136d;
}
.card-custom-flex2 {
  background-color: #fff;
  border-radius: 10px;
}
.card-custom-flex {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
userDocumentsEdit input {
  border: none !important;
}
userDocumentsEdit input:hover {
  border: none !important;
}
.folder-plus {
  font-size: 25px;
  color: #d8d815;
}
.past-button {
  font-size: 30px;
  color: #707070;
}
.past-button {
  font-size: 30px;
  color: #707070;
}

.flies_upload_ad input {
  border: none !important;
  background: #d8d815 !important;
  color: #fff;
  /* margin: 8px 0; */
}

.flies_upload_ad button {
  background: #1663c7;
  color: #fff;
  padding: 6px 41px;
  margin: 18px 10px;
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: 600;
}
