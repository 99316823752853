.c_director_table select {
  background: #5b72cb;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  border-radius: 8px;
}
.countdown-timer {
  font-size: 0.875rem;
  color: #d9534f; /* Bootstrap danger color */
  margin-top: 10px;
}
.userAddedCompanyList {
  font-size: 12px;
  font-weight: 500;
  background: #87cefa;
  padding: 0 13px;
  border-radius: 6px;
}
.companyAvatarInPopup {
  padding: 20px;
  border-radius: 50%;
}
.avatar-text {
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  top: 12%;
  right: 30%;
}
.company-title-popup {
  font-size: 15px;
  font-weight: 600;
}
.hover-company-popup:hover {
  opacity: 20px;
  border-radius: 8px;
  background-color: lightskyblue;
  color: #000;
}
.company-popup-menu {
  /* height: 79px; */
  /* padding: 0px 10px; */
  /* overflow-y: scroll; */
  /* scrollbar-width: thin; */
  /* scrollbar-color: #0d6efd lightskyblue; */
}
.company-popup-menu::-webkit-scrollbar {
  width: 10px; /* Remove scrollbar width */
}
customCompanyInformation {
  overflow-wrap: anywhere;
}
.disabled-sidebar {
  background: none;
  color: white;
  opacity: 0.5; /* Reduce opacity to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate non-clickable */
}
.bank-name {
  font-weight: 600;
  font-size: 20px;
  text-transform: UPPERCASE;
}
.bg-company-list {
  background-color: white;
}
.text-small-custom {
  font-size: 12px;
  margin-top: 5px;
}
.activeCompany-small-text {
  font-size: 12px;
  background-color: rgb(13, 184, 90);
  color: white;
  border-radius: 5px;
  padding: 4px 12px;
  font-weight: 600;
  margin-left: 5px;
}
.activeCompany-small-text2 {
  font-size: 13px;
  border-radius: 5px;

  font-weight: 600;
}
.pendingBank-small-text {
  font-size: 12px;
  background-color: rgb(198, 207, 16);
  color: white;
  border-radius: 5px;
  padding: 4px 23px;
  font-weight: 600;
  margin-left: 5px;
}
.step-arrow {
  left: -11%;
  top: -3px;
  font-size: 25px;
  color: #1976d2;
}
.processingBank-small-text {
  font-size: 12px;
  background-color: rgb(28, 49, 214);
  color: white;
  border-radius: 5px;
  padding: 4px 23px;
  font-weight: 600;
  margin-left: 5px;
}
.activeBank-small-text {
  font-size: 12px;
  background-color: rgb(13, 184, 90);
  color: white;
  border-radius: 5px;
  padding: 4px 23px;
  font-weight: 600;
  margin-left: 5px;
}
.profile_c_p {
  margin: 5px 0;
  padding: 0px !important;
}
.c_profile_table {
  border: 2px solid #87cefa;
}
.c_profile_table tr {
  border: 2px solid #87cefa;
}
.c_profile_table th {
  background-color: #050f26;
  color: #fff;
}
.c_profile_table td {
  background-color: #87cefa;
  color: #050f26;
  border: 2px solid #fff;
  font-weight: 500;
}
.c_director_table th {
  border: 2px solid #fff;
  padding: 6px 10px;
}
.shear_c {
  background-color: #87cefa;
  border: 2px solid #000 !important;
}
.card_c_s {
  margin-top: 20px;
  margin-bottom: 20px;
}
.hover-company-popup {
  cursor: pointer;
}
.file-explorer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.breadcrumbs {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.breadcrumbs ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs li {
  display: inline-block;
  margin-right: 10px;
}

.breadcrumbs a {
  text-decoration: none;
  color: #333;
}

.navigation-bar {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Corporate Invoice CSS */

.invoice-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  border: 2px solid #333; /* Dark Gray */
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

.invoice-header {
  border-bottom: 2px solid #333; /* Dark Gray */
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 24px;
  color: #333; /* Dark Gray */
  margin: 0;
}

.company-info {
  color: #333; /* Dark Gray */
}

.invoice-details {
  display: flex;
  flex-wrap: wrap;
}

.section {
  flex: 1;
  margin-right: 20px;
}

.director-info,
.shareholder-info,
.payment-history {
  margin-bottom: 20px;
}

.payment-history .paid {
  color: #28a745; /* Green */
}

.payment-history .unpaid {
  color: #dc3545; /* Red */
}

strong {
  font-weight: bold;
}

.document-heading {
  /* font-weight: 600; */
  margin-bottom: 10px !important;
  /* text-transform: uppercase; */
}
customDocumentTab .tab {
  font-size: 16px;

  padding: 10px 0px;
  margin: 0px 8px;
  cursor: pointer;
  border: none;
  background: none;
  position: relative;
}

/* Active tab styles */
customDocumentTab .tab.active {
  color: #1663c7; /* White color for active tab text */
}
customDocumentTab .custom-document-tab {
  font-weight: 600;
}

/* Active tab underline */
customDocumentTab .tab.active::after {
  content: "";
  position: absolute;
  width: 100%;
  border-radius: 20px;
  height: 3px;
  background-color: #1663c7; /* Red underline for active tab */
  left: 0;
  bottom: 7px; /* Adjust as needed */
}

.view-button {
  height: 15px;
}
.search-input {
  border: 1px solid #1663c7 !important;
  border-radius: 5px !important;
  padding: 5px !important;
  margin-top: 20px;
  width: 100% !important;
  height: 35px !important;
  font-size: 16px !important;
}

.folderFile {
  font-size: 25px;
}

.breadcrumb-nav button {
  background: none;
  border: none;
  color: #ffff;
  cursor: pointer;
  font-size: 14px;
  background: blue;
  min-width: 80px;
  min-height: 30px;
  border-radius: 7px;
  margin: 0 10px;
}

.breadcrumb-nav button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.breadcrumb-nav span {
  font-size: 14px;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.file-table th,
.file-table td {
  text-align: left;
  border-bottom: 1px solid #1663c7;
}

.file-table tr:hover {
  background-color: #f1f1f1;
}

.file-table i {
  margin-right: 10px !important;
  margin: 13px 0;
}

.file-table a {
  text-decoration: none;
  color: black;
}

.file-table a:hover {
  text-decoration: underline;
}

.verified-company {
  color: #1849e1;
  width: 30px;
  height: 21px;
}
/* Banner styles */
.banner {
  background: url("https://images.pexels.com/photos/221553/pexels-photo-221553.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
}

@media (max-width: 991px) {
  .banner {
    max-width: 100%;
  }
}

/* Content styles */
.content {
  background-color: #fff;
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  padding: 19px 72px;
}
.bank-content {
  display: flex;
  width: 100%;

  padding: 19px 72px;
}
.processing-lottie {
  height: 26px;
  width: 26px;
}

@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}

.profile-img {
  width: 150px;
  height: 150px;
  margin-top: 120px;
  border-radius: 50%;
  object-fit: cover;
  top: 4rem;
  background: #ffffff;
}
.bank-profile-img {
  width: 150px;
  height: 150px;
  margin-top: 120px;

  object-fit: cover;
  top: 4rem;
  padding: 5px;
  box-shadow: #000000 0px 0px 10px;
  background: #ffffff;
}
.details {
  margin-left: 12rem;
  font-weight: 600;
}
.bank-status-button-pending {
  background-color: #c1fb1a;
  color: #000000;
  padding: 0px 9px;
  border-radius: 7px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: 0.1px solid black;
}
.bank-status-button-processing {
  background-color: #4351f6;
  color: #fff3f3;
  padding: 0px 9px;
  border-radius: 7px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.bank-status-button-active {
  background-color: #3ef034;
  color: #000000;
  padding: 0px 9px;
  border-radius: 7px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: 0.1px solid black;
}

.grid-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 240px; */
  height: 240px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.grid-item.folder:hover,
.grid-item.file:hover {
  background-color: #f0f0f0;
}

.grid-item i {
  font-size: 105px;
  margin: 15px;
}

.grid-item span,
.grid-item a {
  display: block;
  margin-top: 5px;
  text-decoration: none;
  color: inherit;
  overflow-wrap: anywhere;
}

paymentRecordTable .table {
  margin-bottom: 0;
  color: #6c757d; /* Replace with the equivalent value for $text-muted */

  thead th {
    border-top: 0;
    border-bottom-width: 1px;
    background: #0080ff;
    color: #fff;
    font-weight: 500; /* Replace with the equivalent value for $font-weight-medium */
  }

  thead th i {
    margin-left: 0.325rem;
  }

  th,
  td {
    vertical-align: middle;
    font-size: 14px; /* Replace with the equivalent value for $default-font-size */
    line-height: 1;
  }

  th img,
  td img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  th .badge,
  td .badge {
    margin-bottom: 0;
  }

  &.table-borderless {
    border: none;
  }

  &.table-borderless tr,
  &.table-borderless td,
  &.table-borderless th {
    border: none;
  }
}
.emi-details-container {
  background: linear-gradient(
    to right,
    #83a4d4,
    #b6fbff
  ); /* Gradient background */
  padding: 20px;
  border-radius: 8px;
}

/* Card styling */
.emi-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for card */
  /* White background for card */
  border-radius: 8px;
}

/* Custom Table styling */
.emi-custom-table {
  width: 100%;
  margin-bottom: 0;
  color: #333; /* Darker text color */
  border-collapse: collapse;
  font-weight: 600;
  border: 1px solid rgb(40, 28, 53);
}

.emi-custom-table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  background: linear-gradient(45deg, #060f48, #5f28ab); /* Gradient header */
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
}

/* Row styling based on status */
.paid-row {
  background: linear-gradient(
    45deg,
    #3bff36,
    #f7f7f7
  ); /* Light green background for paid rows */
}

.pending-row {
  background: linear-gradient(
    45deg,
    #ff9536,
    #f7f7f7
  ); /* Light yellow background for pending rows */
}

.upcoming-row {
  background: linear-gradient(
    45deg,
    #f2ff00,
    #f7f7f7
  ); /* Light gray background for upcoming rows */
}

.paid-row:hover,
.pending-row:hover,
.upcoming-row:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Hover shadow for rows */
  transform: translateY(-2px); /* Slight lift on hover */
  transition: all 0.3s ease;
}

.emi-custom-table td,
.emi-custom-table th {
  vertical-align: middle;
  font-size: 14px;
  line-height: 1;
  padding: 8px;
  text-align: center;
}
.emi-custom-table tr {
  border-bottom: 1px solid #24222b;
}
.border-bottom-emi-custom {
  border-top: 1px solid #a99595;
}
/* Icon colors */
.emi-custom-table .text-success {
  color: #28a745; /* Success color for Paid status */
}

.emi-custom-table .text-warning {
  color: #ffc107; /* Warning color for Pending status */
}
.paidStatusBox {
  border-radius: 20px;
}

.paidStatusBtn {
  margin: 0px 8px;
}

.singleDirector {
  font-size: 14px;
  /* font-weight: 500; */
}

.singleDirectorCard {
  background: white;
  border: 1px solid #292525;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  overflow: hidden;
}
.singleDirectorCard2 {
  background: white;
  border: 1px solid #292525;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
}
.align-share-center {
  display: flex;
  align-items: center;
}
expenseComponent .cardDesign {
  width: 400px;
  height: 240px;
  perspective: 1000px;
}

@keyframes flip {
  0%,
  100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}

expenseComponent .flip {
  width: inherit;
  height: inherit;
  transition: 0.7s;
  transform-style: preserve-3d;
  animation: flip 2.5s ease;
}

expenseComponent .front,
.back {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #111, #555);
  overflow: hidden;
}

expenseComponent .front {
  transform: translateZ(0);
}

expenseComponent .strip-bottom,
.strip-top {
  position: absolute;
  right: 0;
  height: inherit;
  background-image: linear-gradient(to bottom, #ff6767, #ff4545);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
}

expenseComponent .strip-bottom {
  width: 200px;
  transform: skewX(-15deg) translateX(50px);
}

expenseComponent .strip-top {
  width: 180px;
  transform: skewX(20deg) translateX(50px);
}

expenseComponent .logo {
  height: 100px;
  position: absolute;
  top: -27px;
  right: 2px;
  width: 100px;
}

expenseComponent .investor {
  position: absolute;
  top: 30px;
  left: 25px;
  text-transform: uppercase;
  font-weight: 600;
}

expenseComponent .chip {
  position: relative;
  top: 60px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
  overflow: hidden;
}

expenseComponent .chip .chip-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #333;
}

expenseComponent .chip .chip-line:nth-child(1) {
  top: 13px;
}

expenseComponent .chip .chip-line:nth-child(2) {
  top: 20px;
}

expenseComponent .chip .chip-line:nth-child(3) {
  top: 28px;
}

expenseComponent.chip .chip-line:nth-child(4) {
  left: 25px;
  width: 1px;
  height: 50px;
}

expenseComponent .chip .chip-main {
  width: 20px;
  height: 25px;
  border: 1px solid #333;
  border-radius: 3px;
  background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
  z-index: 1;
}

expenseComponent .wave {
  position: relative;
  top: 20px;
  left: 100px;
}

expenseComponent .card-number {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 25px 10px;
  font-size: 20px;
  font-family: "cc font", monospace;
}

expenseComponent .end {
  margin-left: 25px;
  text-transform: uppercase;
  font-family: "cc font", monospace;
}

expenseComponent .end .end-text {
  font-size: 9px;
  color: rgba(255, 255, 255, 0.8);
}

expenseComponent .card-holder {
  margin: -2px 25px;
  text-transform: uppercase;
  font-family: "cc font", monospace;
}

expenseComponent .master {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
}

expenseComponent .circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

expenseComponent .master-red {
  background-color: #eb001b;
}

expenseComponent .master-yellow {
  margin-left: -10px;
  background-color: rgba(255, 209, 0, 0.7);
}

expenseComponent .back {
  transform: rotateY(180deg) translateZ(0);
  background: #9e9e9e;
}

expenseComponent .back .strip-black {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 50px;
  background: black;
}

expenseComponent .back .ccv {
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  height: 36px;
  width: 90%;
  padding: 10px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: right;
  letter-spacing: 1px;
  color: #000;
  background: white;
}

expenseComponent .back .ccv label {
  display: block;
  margin: -30px 0 15px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
}

expenseComponent .back .terms {
  position: absolute;
  top: 150px;
  padding: 20px;
  font-size: 10px;
  text-align: justify;
}
expenseComponent .compnay_info {
  margin-top: 30px !important;
}
expenseComponent .pagination {
  margin-top: 38px;
  display: flex;
  justify-content: center;
}
expenseComponent .cell {
  border-bottom: 1px solid #0b5ed7 !important;
}
.sort-menu-icon {
  font-size: 25px;
}
.custom-dropdown-menu {
  z-index: 1000;
}

/*------------- folder box----------- */
.file-table_docu .folders_styling {
  width: 150px;
  height: 105px;
  margin: 20px 50px;
  float: left;
  position: relative;
  background: rgb(110, 166, 239);
  background: linear-gradient(
    183deg,
    rgba(110, 166, 239, 1) 11%,
    rgba(30, 92, 172, 1) 100%
  );
  border-radius: 0 6px 6px 6px;
  transition: 0.1s ease-out;
  /* box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.59); */
  cursor: pointer;
}
/*-------------folder box----x------- */
.file-table_docu .folders_styling:hover {
  transform: scale(1.2);
}
/*-------------file opener----------- */
.file-table_docu .folders_styling:before {
  content: "";
  width: 46%;
  height: 21px;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  border-radius: 4px 0px 0 0;
  background-color: #1e5cac;
  position: absolute;
  top: -10.3px;
  left: 0px;
}
/*-------------file opener----x------- */

/*------------- Document ----------- */
.file-table_docu .folders_styling:after {
  content: "";
  width: 29%;
  height: 5px;
  border-radius: 2px 2px 0 0;
  background-color: #ffffff;
  position: absolute;
  top: 5.6px;
  left: 4px;
}

/*------------- folder box----------- */
.file-table_docu .folders_styling2 {
  width: 50px;
  height: 33px;
  margin: 20px 0px;
  float: left;
  position: relative;
  background: rgb(110, 166, 239);
  background: linear-gradient(
    183deg,
    rgba(110, 166, 239, 1) 11%,
    rgba(30, 92, 172, 1) 100%
  );
  border-radius: 0 6px 6px 6px;
  transition: 0.1s ease-out;
  /* box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.59); */
  cursor: pointer;
}
.folder-file-name-absolute {
  /* position: absolute; */
  bottom: 20px;
  left: 80px;
}
.grid-file-check-position {
  position: absolute;
  top: 20px;
  right: 3px;
}
/*-------------folder box----x------- */
.folders_styling2:hover {
  transform: scale(1.2);
}
/*-------------file opener----------- */
.folders_styling2:before {
  content: "";
  width: 46%;
  height: 21px;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  border-radius: 4px 0px 0 0;
  background-color: #1e5cac;
  position: absolute;
  top: -10.3px;
  left: 0px;
}
/*-------------file opener----x------- */

/*------------- Document ----------- */
.folders_styling2:after {
  content: "";
  width: 29%;
  height: 5px;
  border-radius: 2px 2px 0 0;
  background-color: #ffffff;
  position: absolute;
  top: 5.6px;
  left: 4px;
}

.file-table_docu .filename {
  cursor: pointer;
  display: flex;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  margin-top: 23px;
}
.back-sec {
  border: 1px solid #1663c7;
  border-radius: 7px;
}
.file-table th {
  background: #1663c7;
  color: #fff;
  text-align: left;
  font-weight: 600;
}
::placeholder {
  color: #1663c7;
}
.user-details h6 {
  text-transform: uppercase;
}
.cell {
  border-bottom: 1px solid #1663c7;
}
.files_style {
  font-size: 25px;
  margin: 20px 0;
}
.folder span {
  font-weight: 700;
}
.docu_pagi {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.breadcrumb-nav span {
  font-weight: 700;
  color: #1663c7;
  border: 1px solid #1663c7;
  padding: 5px 25px;
  border-radius: 5px;
  margin: 0 5px;
}
body {
  background-color: #e0f2ff;
}

.file-table a:hover {
  color: #1663c7;
}
.confirm_btn {
  background: #4c36d6;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  padding: 10px;
  margin: 0px 3px;
  border: 1px solid #fff;
}

.table-padding-left {
  padding-left: 30px !important;
}
.table-heading-min-width {
  min-width: 130px;
}
.user-management-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.user-management-avatar-icon {
  width: 50px;
  height: 50px;
}
.file-name-text-size-md {
  overflow-wrap: anywhere;
}
.notification-heading {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: -4px;
}
.notification-unread {
  font-size: 13px;
  font-weight: 600;
  background: #14b44c;
  color: white;
  padding: 2px 9px;
  border-radius: 25px;
  margin-left: 10px;
}
.notification-hover:hover {
  background: #83cfff;
  cursor: pointer;
}
.local-date-notification {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
}

.bell-icon {
  min-height: 30px;
  min-width: 30px;
  color: #0c61dd;
  margin-right: 12px;
}
.file-upload-title {
  text-align: center;
  font-size: 15px;
  margin: 15px 10px;
}

/* upload provide data css */
.upload-custom-button {
  text-align: center;
  position: relative;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid rgb(61, 106, 255);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  background: #103da0;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  transition: all 0.2s ease-in;
}

.upload-custom-button:hover {
  background: rgb(61, 106, 255);
  box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.upload-custom-button:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

.upload-custom-button::before {
  content: "";
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

.upload-custom-button:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}
.cursor-pointer {
  cursor: pointer;
}

/*Small Devices, Tablets*/
@media only screen and (max-width: 1024px) {
  .edit-single-director-direction {
    flex-direction: column-reverse;
  }
}

/*Small Devices, Tablets*/
@media only screen and (max-width: 767px) {
  .welcome_dashbaord {
    padding-right: 14px;
  }
  .css-1bdad8q-MuiResponsiveChart-container {
    width: 330px !important;
  }
  .ad_index {
    display: block !important;
  }
  .ad_index_card {
    margin-bottom: 10px;
  }
  .ad_index_card_main {
    display: block !important;
    width: 90%;
  }
  .ad_index_card_main2 {
    width: 100% !important;
  }
  .ad_index_peyment {
    width: 100% !important;
    margin-top: 15px;
  }
  .usermange_ad {
    display: block !important;
  }
  .pay_ad {
    font-size: 14px;
  }
  .profile_button {
    text-align: center;
  }
  .edit_profile {
    margin-bottom: 50px;
  }
  .profile {
    margin-top: 0px;
  }
  .edit_ad_user {
    margin-top: -47px;
  }
  .heading_ad_userd {
    font-size: 12px;
  }
  .ad_infouser_card {
    display: block !important;
  }
  .ad_infouser_card .card {
    width: 100% !important;
  }
  .ad_infouser_card .cardDesign {
    width: 328px;
    height: 215px;
  }
  .invoices_right {
    margin-top: -8px;
  }
  .invoices_img {
    max-width: 325px;
    max-height: 441px;
  }

  .table-mobile-responsive-row {
    display: flex !important;
    flex-direction: column !important;
  }
  .c_profile_table tr {
    border: 0px;
  }
  .table_index tr {
    border: 0px;
  }
  iframe {
    border: 0;
    width: 100%;
  }
  .lottie_index {
    display: none;
    margin-top: 20px;
    background: #16315e;
    max-width: 100%;
  }
  .btn-2_dash a {
    /* width: 158px; */
    background-color: #0f68ea;
    color: #fff;
    padding: 10px 20px;
    width: 100%;
  }
  .btn-1_dash a {
    padding: 10px 20px;
    font-size: 15px;
  }
  .index-flex-right-sight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .column-right_dash_first {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .document_billing {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .index-flex-direction {
    flex-direction: column-reverse;
  }
  .index-name-and-contact {
    flex-direction: column;
  }
  .user_dash_name {
    color: #050f26;
    text-align: center;
    font-size: 24px;
  }
  .dashboards_first_left p {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
  }
  .document_billing a {
    width: 100%;
  }
  .company_nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .director-card {
    margin: 0 !important;
    margin-top: 1rem !important;
    padding: 0 !important;
  }
  .director-title {
    padding: 15px !important;
  }
  .profile-container {
    margin: 0px;
  }
  .profile {
    margin-top: 0px;
  }
  .bank-profile-img {
    margin-top: 160px;

    top: 4rem;
    left: 20px;
    padding: 5px;
  }
  .details {
    margin-left: 0rem;
  }
  .bank-content {
    display: block;
    padding: 0px;
  }
  .bank-name-select {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 60px;
  }
  .update-profile-button {
    width: 100%;
  }
  .file-label {
    padding: 14px 20px;
    width: 100%;
  }
  .placeholder-icon {
    width: 100px;
    height: 100px;
  }
  .file-name {
    margin-left: 0px;
    text-align: center;
    color: #777;
  }
  customDocumentTab .tab {
    font-size: 15px;
  }
  .file-table th,
  .file-table td {
    padding: 8px;
    font-size: 14px;
  }
  .file-table_docu .folders_styling2 {
    width: 34px;
    height: 22px;
    margin: 0px;
  }
  .file-table i {
    margin: 9px 0;
  }
  .table-font-weight {
    font-weight: 600;
  }
  .file-table-margin {
    margin-left: 15px;
  }
  .css-1bdad8q-MuiResponsiveChart-container {
    width: 330px !important;
  }
  .ad_index {
    display: block !important;
  }
  .ad_index_card {
    margin-bottom: 10px;
  }
  .ad_index_card_main {
    display: block !important;
    width: 90%;
  }
  .ad_index_card_main2 {
    width: 100% !important;
  }
  .ad_index_peyment {
    width: 100% !important;
    margin-top: 15px;
  }
  .usermange_ad {
    display: block !important;
  }
  .pay_ad {
    font-size: 14px;
  }
  .profile_button {
    text-align: center;
  }
  .edit_profile {
    margin-bottom: 50px;
  }
  .profile {
    margin-top: 0px;
  }
  .edit_ad_user {
    margin-top: -47px;
  }
  .heading_ad_userd {
    font-size: 12px;
  }
  .ad_infouser_card {
    display: block !important;
  }
  .ad_infouser_card .card {
    width: 100% !important;
  }
  .ad_infouser_card .cardDesign {
    width: 328px;
    height: 215px;
  }
  .invoices_right {
    margin-top: -8px;
  }
  .invoices_img {
    max-width: 325px;
    max-height: 441px;
  }
  .invoices_img {
    max-width: 325px;
    max-height: 441px;
  }
  .file-name-text-size {
    overflow-wrap: anywhere;
  }
  expenseComponent .cardDesign {
    width: 340px;
    height: 222px;
    perspective: 1000px;
  }
  .front {
    font-size: 14px;
  }
  userManagementComponent .card {
    margin: 0px;
    padding: 0px;
  }

  staticsUserDetails .card.card-img-holder {
    margin-bottom: 10px;
  }
  userManagementComponent .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }
  .flies_upload_ad button {
    margin: 6px 0px 0px 0px !important;
    padding: 10px 10px !important;
    width: 100% !important;
    border-radius: 6px;
  }

  .file_upload-form_button {
    width: 100% !important;
  }
  .table-padding-left-mobile {
    padding-left: 5px !important;
  }
  .font-card-statics-mobile {
    font-size: 16px;
  }
  .banner {
    min-height: 16vh;
  }
  .content {
    background-color: #e0f2ff;
    align-items: baseline;
    margin-top: 0px;
    padding: 15px 15px;
  }
  .account_menu2 {
    overflow-wrap: anywhere;
    top: unset !important;
    bottom: 110px !important;
  }
  .account_menu3 {
    overflow-wrap: anywhere;
    top: unset !important;
    bottom: 50vh !important;
  }
  .role-detector2 {
    width: 100px;
    height: 35px;
    font-size: 11px;
  }
  .edit-single-director-direction {
    flex-direction: row;
  }
  .singleDirectorCard {
    margin: 10px !important;
  }
  .singleDirectorPadding {
    width: 100%;
    padding: 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.paymentDetails-card {
  background: #0f0c29;
  background: linear-gradient(to right bottom, #443e89, #0f0c29);
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgb(84 90 106);
}
.card-info-holder {
  color: white;
  box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
  background-color: #b140bc;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.938rem;
}

priceStatusCard .card {
  background: #0f0c29;
  background: linear-gradient(to right bottom, #443e89, #0f0c29);
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgb(84 90 106);
}

priceStatusCard .small-text,
.title,
.desc {
  font-weight: 600;
}

priceStatusCard .title,
.desc {
  margin: 8px 0;
}

priceStatusCard .small-text {
  color: #488aec;
  font-size: 30px;
}

priceStatusCard .title {
  color: #fff;
  font-size: 26px;
  line-height: 26px;
}

priceStatusCard .desc {
  color: rgb(151 153 167);
  font-size: 13px;
}

priceStatusCard .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

priceStatusCard .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 6px;
  text-decoration: none;
}

priceStatusCard .button:first-child {
  box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
  background-color: #488aec;
}

priceStatusCard .button:last-child {
  box-shadow: 0 4px 6px -1px #0b162531, 0 2px 4px -1px #0e192b17;
  background-color: #fff;
}

priceStatusCard .icon {
  height: 25px;
  width: 25px;
}

priceStatusCard .icon svg {
  width: 100%;
  height: 100%;
}

priceStatusCard .icon svg:last-child {
  fill: #000;
}

priceStatusCard .button-text {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

priceStatusCard .button-text span:first-child {
  font-size: 12px;
  font-weight: 600;
}

priceStatusCard .google span:first-child {
  color: rgb(219 206 253);
}

priceStatusCard .apple span:first-child {
  color: rgb(81 87 108);
}

priceStatusCard .google span:last-child {
  color: #fff;
  font-weight: 700;
}

priceStatusCard .apple span:last-child {
  color: #000;
  font-weight: 700;
}

/* end */

transactionCard .rating-card {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #0f0c29;
  background: linear-gradient(to right bottom, #443e89, #0f0c29);
  padding: 20px 20px;
  border-radius: 10px;
  gap: 10px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.058);
}
transactionCard .rating-card2 {
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0f0c29;
  background: linear-gradient(to right bottom, #443e89, #0f0c29);
  padding: 20px 20px;
  border-radius: 10px;
  gap: 10px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.058);
}
transactionCard .rating-card3 {
  height: fit-content;
  display: flex;

  justify-content: space-evenly;
  align-items: center;
  background: #0f0c29;
  background: linear-gradient(to right bottom, #443e89, #0f0c29);
  padding: 20px 20px;
  border-radius: 10px;
  gap: 10px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.058);
}
transactionCard .rating-card4 {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0f0c29;
  background: linear-gradient(to right bottom, #493ec6, #292360);
  padding: 20px 20px;
  border-radius: 10px;
  margin: 5px -12px;
  gap: 10px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.058);
}

transactionCard .text-wrapper-tr {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
transactionCard .text-wrapper-tr4 {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
}
transactionCard .text-primary-tr {
  color: white;
  font-size: 15px;
  font-weight: 600;
}

transactionCard .socials-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(150, 150, 150);
}

.current-active-status {
  background: #379d37;
  color: white;
  padding: 4px 13px;
  border-radius: 10px;
  font-size: 12px;
}

.verticalLine {
  height: 100%;
  border-left: 2px solid white;
  position: absolute;
  top: 0px;
}

.verticalLine2 {
  height: 100%;
  border-left: 2px solid white;
  position: absolute;
  top: 0px;
}

.payment-width-fixing-75 {
  width: 75% !important;
}
.payment-width-fixing-25 {
  width: 25% !important;
}
.billing-width-fixing-50 {
  width: 50% !important;
}
.profile-img {
  width: 150px;
  border-radius: 50%;
  top: 4rem;
  background: #ffffff;
}
.paymentDetails_select_package {
  margin: 20px 83px 0px 1px;
  padding: 50px 20px 0px;
}
@media screen and (max-width: 768px) {
  .non-active_company_menu {
    padding: 8px 10px;
  }
  .paymentDetails_select_package {
    margin: 20px 0px 0px 0px;
    padding: 40px 30px;
  }
  .active_company_menu {
    padding: 8px 10px;
  }
  .verticalLine2 {
    display: none;
  }
  .billing-width-fixing-50 {
    width: 100% !important;
  }
  .payment-width-fixing-75 {
    width: 100% !important;
  }
  .payment-width-fixing-25 {
    width: 100% !important;
  }
  .profile-img {
    width: 150px;
    margin-top: 0px;
    border-radius: 50%;
    top: 0rem;
    background: #ffffff;
    position: initial !important;
  }
}
.bold-text-billing {
  font-weight: 600;
}

.last_billing_card {
  border: 1px solid;
  margin: 20px;
  border-radius: 8px;
  background: linear-gradient(
    to right bottom,
    rgb(122, 110, 253),
    rgb(22, 24, 59)
  );
}

.billing_status {
  font-size: 15px;
  background: linear-gradient(to right bottom, rgb(20 54 255), rgb(84 206 150));
  color: white;
}

.total_price_billing {
  color: white;
  background: linear-gradient(to right bottom, rgb(20 54 255), rgb(84 206 150));
}

.total_count_billing {
  color: white;
  background: linear-gradient(to right bottom, rgb(20 54 255), rgb(84 206 150));
}

.css-1bdad8q-MuiResponsiveChart-container {
  width: 100% !important;
  height: 100% !important;
}
.css-1bdad8q-MuiResponsiveChart-container > svg {
  width: 100% !important;
  height: 100% !important;
}

/* .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  overflow-y: auto;
  position: unset;
  max-width: 370px;
} */
css-3bmhjh-MuiPaper-root-MuiPopover-paper {
}
.total_count_billing {
  color: white;
  background: linear-gradient(to right bottom, rgb(20 54 255), rgb(84 206 150));
}

.total_count_billing {
  color: white;
  background: linear-gradient(to right bottom, rgb(20 54 255), rgb(84 206 150));
}

.total_count_billing {
  color: white;
  background: linear-gradient(to right bottom, rgb(20 54 255), rgb(84 206 150));
}
