customTable .table,
customTable .row {
  width: 100% !important;
}

customTable .row {
  display: table-row;
  background: #fff;

  &:hover {
    background-color: #cacbdb;
  
  }
}
customTable .custom-width-table {
 margin: 0px 30px;
}

customTable .header {
  color: #fff;
  background: #6c7ae0;

  .cell {
    font-size: 14px;
    padding: 10px 0;
  }
}

customTable .details-company-link {
  background: #1aa31ab6;
  color: #fff;
  padding: 2px 2px;
  text-align: center;
  border-radius: 20px;
  &:hover {
    background: #135527;
    color: #fff;
    cursor: pointer;
  }
}

customTable .cell {
  display: table-cell;
  font-size: 14px;
  line-height: 1.2;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;

  &:nth-child(1) {
    width: 360px;
    padding-left: 20px;
  }

  &:nth-child(2) {
    width: 160px;
  }

  &:nth-child(3) {
    width: 250px;
  }

  &:nth-child(4) {
    width: 190px;
  }
}

staticsUserDetails .card.card-img-holder {
  position: relative;
  overflow: hidden;
}
staticsUserDetails .stretch-card > .card {
  width: 100%;
  min-width: 100%;
}
staticsUserDetails .card {
  border: 0;
  background: #fff;
}
staticsUserDetails .bg-gradient-danger {
  background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}
staticsUserDetails .bg-gradient-info {
  background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
}
staticsUserDetails .bg-gradient-success {
  background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}
staticsUserDetails .bg-gradient-pending {
  background: linear-gradient(to right, #84d9d2, #7003ff) !important;
}
staticsUserDetails .card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
staticsUserDetails .card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
}
staticsUserDetails img,
svg {
  vertical-align: middle;
}
staticsUserDetails img,
svg {
  vertical-align: middle;
}
