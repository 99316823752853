body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.logo {
  z-index: 1000;
}
table {
  vertical-align: middle !important;
}

main {
  width: 100%;
  padding: 0px 0px 0px 0px !important;
}

.overflow-table-wrapper {
  overflow-wrap: anywhere;
}
.overflow-direct-wrapper {
  overflow-wrap: anywhere;
}

@media screen and (max-width: 600px) {
  .overflow-table-wrapper {
    overflow-wrap: break-word;
  }
}
